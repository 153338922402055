<template>
    <a href="#" :title="text.add + ' ' + linkName" @click="onClick($event)">{{text.add}}</a>
</template>

<script>
    import {log} from "@/shared/utils";
    import lang from "@/shared/lang";

    export default {
        name: "AddAction",
        props: {
            linkName: {
                type: String
            },
        },
        computed: {
            text() {
                return lang;
            },
        },
        methods: {
            onClick(e) {
                e.preventDefault();

                log("Action fired");

                this.$emit('actionCalled')
            }
        },
    }
</script>