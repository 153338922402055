<template>
    <div class="table-scroller table-shadow">
        <table>
            <thead>
            <tr>
                <th class="w-4/12" :class="[{'hidden' : showNameColumn === false}]">{{text.name}}</th>
                <th :class="[{'w-6/12' : showNameColumn === true}, {'w-10/12' : showNameColumn === false}]">{{text.text}}</th>
                <th class="w-2/12">{{text.actions}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, index) in optionsList" :key="index">
                <td :class="[{'hidden' : showNameColumn === false}]">
                    <Formelement :elementData="row[0]" v-model="row[0].value"
                                 :ref="'element-' + index + '-' + row[0].name"/>
                </td>
                <td>
                    <Formelement :elementData="row[1]" v-model="row[1].value"
                                 :ref="'element-' + index + '-' + row[1].name"/>
                </td>
                <td>
                    <AddAction :linkName="text.row" @actionCalled="addRow()" v-if="index === optionsList.length - 1"/>
                    <DeleteAction :linkName="row.name" @actionCalled="deleteRow(row[0].id)" v-else/>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import {log} from "@/shared/utils";
    import lang from "@/shared/lang";

    import Formelement from "@/components/form/formelement";
    import AddAction from "@/components/actionlinks/add";
    import DeleteAction from "@/components/actionlinks/delete";

    export default {
        name: "TextPicker",
        components: {Formelement, AddAction, DeleteAction},
        props: {
            optionsConfiguration: {
                type: Object,
                default: () => {}
            },
        },
        data() {
            return {
                elementClassString: "hide-label",
                formData: [],
                optionsList: [],
            }
        },
        computed: {
            text() {
                return lang;
            },
            showNameColumn() {
              log(["Show Name Column",this.optionsConfiguration.data]);
              if(this.optionsConfiguration.hasOwnProperty("settings") && this.optionsConfiguration.settings.hasOwnProperty("contentOptionNoName") && this.optionsConfiguration.settings.contentOptionNoName === true){
                return false;
              }
              else {
                return true;
              }
            }
        },
        methods: {
            addRow(refreshOptions = true) {
                log(["ADD ROW"]);
                if (this.validateNew()) {
                    log(["New row ok"]);

                    this.$emit('newOption', {
                        name: this.optionsList[this.optionsList.length - 1][0].value,
                        value: this.optionsList[this.optionsList.length - 1][1].value,
                    }, refreshOptions);
                }
            },
            updateRow(row) {
                log(["UPDATE ROW", row]);

                this.$emit('updateOption', {
                    id: row[0].id,
                    name: row[0].value,
                    value: row[1].value,
                });
            },
            deleteRow(id) {
                log(["DELETE ROW", id]);

                this.$emit('deleteOption', id);
            },
            save() {
                log(["SAVING TEXT PICKER", this.optionsList[this.optionsList.length - 1][0].value.trim().length, this.optionsList[this.optionsList.length - 1][1].value.trim().length]);

                // Make sure all the errors are cleared from the new form
                this.clearNewForm();

                let allOK = true;

                // TODO - length here on index 0 should only be tested if

                // If there is values in the new row then they need to add it or clean it up first
                if (this.optionsList[this.optionsList.length - 1][1].value.trim().length > 0 && !this.validateNew()) {

                    log(["NEW HAS VALUES BUT ISN'T VALID"]);

                    allOK = false;
                }
                else if(this.optionsList[this.optionsList.length - 1][1].value.trim().length > 0 && this.validateNew()){

                    if(this.validateAll()) {
                        log(["Validated all ok with new row"]);

                        log(["ADDING NEW ROW"]);
                        this.addRow(false);
                    }
                    else
                    {
                        allOK = false;
                    }
                }
                else
                {

                    if(!this.validateAll()) {
                        log(["Validated all ok without new row"]);
                        allOK = false;
                    }
                }

                if(allOK){
                    // TODO: LOOP THROUGH ALL OPTIONS AND REAPPLY TO MODEL IN CASE OF TEXT CHANGE
                    for(let index = 0; index < (this.optionsList.length - 1); index++) {
                        this.updateRow(this.optionsList[index]);
                    }
                }

                return allOK;
            },
            validateAll() {
                let pass = true;

                log(["Saving form"]);
                log(["Refs: ", this.$refs]);

                // We don't want to validate the new row
                for(let index = 0; index < (this.optionsList.length - 1); index++) {
                    this.optionsList[index].forEach(element => {
                        log(["Ref", element, "element-" + index + "-" + element.name, this.$refs["element-" + index + "-" + element.name], this.$refs["element-" + index + "-" + element.name][0]]);
                        if (!this.$refs["element-" + index + "-" + element.name][0].validateElement()) {
                            pass = false;
                        }
                        log("Current pass state: " + pass);
                    });
                }

                return pass;
            },
            validateNew() {
                let pass = true;

                log(["Validate new form"]);
                log([this.$refs]);

                let lastIndex = this.optionsList.length - 1;

                this.optionsList[this.optionsList.length - 1].forEach(element => {
                    log(["Ref", element, "element-" + lastIndex + "-" + element.name, this.$refs["element-" + lastIndex + "-" + element.name], this.$refs["element-" + lastIndex + "-" + element.name][0]]);
                    if (!this.$refs["element-" + lastIndex + "-" + element.name][0].validateElement()) {
                        pass = false;
                    }
                    log("Current pass state: " + pass);
                });

                return pass;
            },
            clearNewForm() {
                log(["Clear new form"]);
                let lastIndex = this.optionsList.length - 1;
                this.optionsList[this.optionsList.length - 1].forEach(element => {
                    log(["Ref", element, "element-" + lastIndex + "-" + element.name, this.$refs["element-" + lastIndex + "-" + element.name], this.$refs["element-" + lastIndex + "-" + element.name][0]]);
                    this.$refs["element-" + lastIndex + "-" + element.name][0].clearError();
                });
            },
            initOptionsList() {
                log(["Intialising options", this.optionsConfiguration.data.options.length, this.optionsConfiguration.data.options]);
                this.optionsList = [];

                this.optionsConfiguration.data.options.forEach((option, index) => {
                    log(["Option", option, index]);
                    this.optionsList.push(this.createFormRow(index));
                });

                // then add a blank for the new line
                this.optionsList.push(this.createFormRow("new"));
            },
            createFormRow(rowCount) {
                return [
                    {
                        id: rowCount !== "new" ? this.optionsConfiguration.data.options[rowCount].id : "new",
                        type: 'text',
                        name: `name-${rowCount}`,
                        label: 'Name',
                        placeholder: "",
                        tooltip: "",
                        value: rowCount !== "new" ? this.optionsConfiguration.data.options[rowCount].name : "",
                        validate: {
                            type: 'string',
                            required: false,
                            max: this.optionsConfiguration.editor.nameMaxLength
                        },
                        classString: "hide-label"
                    },
                    {
                        id: rowCount !== "new" ? this.optionsConfiguration.data.options[rowCount].id : "new",
                        type: 'text',
                        name: `value-${rowCount}`,
                        label: 'Value',
                        placeholder: "",
                        tooltip: "",
                        value: rowCount !== "new" ? this.optionsConfiguration.data.options[rowCount].value : "",
                        validate: {
                            type: 'string',
                            required: true,
                            max: this.optionsConfiguration.settings.maxLength
                        },
                        classString: "hide-label"
                    }
                ];
            },
            refreshOptions() {
                log(["Refreshing options", this.optionsConfiguration]);
                this.initOptionsList();
            }
        },
        created() {
            log(["Text picker created", this.optionsConfiguration]);
            this.initOptionsList();
        },
    }
</script>