<template>
    <div v-if="contentStatus">
        <component :is="templateSchemaSettingsCollectionPropertyEditOptionsComponent"
                   :optionsConfiguration="templateSchemaSettingsCollectionPropertyConfiguration"
                   @newOption="newPropertyOptionAdded"
                   @updateOption="updatePropertyOption"
                   @deleteOption="deletePropertyOptionByID"
                   ref="propertyEditor"></component>

        <a href="#" :title="text.save" class="btn btn-primary btn-smyspace" @click="save($event)" @keyup.enter="save($event)">{{text.save}}</a>
        <router-link :to="{ name: 'AdminTemplateSchemaSettingsCollectionList' }" :title="text.cancel" class="btn btn-tertiary">{{text.cancel}}</router-link>
    </div>
</template>

<script>
    import lang from '@/shared/lang';
    import {log} from "@/shared/utils";
    import {MESSAGE_TYPE_ERROR} from "@/shared/consts";

    import {mapActions, mapState} from "vuex";

    import {templateSchemaSettingsCollectionPropertyActionsMixin} from "@/views/mixins/templateSchemaSettingsCollectionPropertyActions";

    import Form from "@/components/form/form";
    import TextPicker from "@/components/propertyeditors/picker_text";
    import TextareaPicker from "@/components/propertyeditors/picker_textarea";

    export default {
        name: 'TemplateSchemaSettingsCollectionEdit',
        mixins: [templateSchemaSettingsCollectionPropertyActionsMixin],
        components: {Form,TextPicker,TextareaPicker},
        data() {
            return {
                contentStatus: true,
                templateSchemaSettingsCollectionPropertyEditOptionsComponent: false,
                templateSchemaSettingsCollectionPropertyConfiguration: {}
            }
        },
        computed: {
            ...mapState(['pageTitle']), // imports from store
            text() {
                return lang;
            },
            message() {
                return this.$route.params.message
            },
            templateSchemaSettingsCollectionID() {
                return this.$route.params.id;
            },
            templateSchemaSettingsCollectionPropertyAlias() {
                return this.$route.params.alias;
            },
        },
        methods: {
            ...mapActions(["setPageTitleAction"]),
            async initPropertyOptionsEdit() {
                let allOK = true;

                if(await this.loadTemplateSchemaSettingsCollectionPropertyByAlias()){

                    // CHECK IF TYPE IS EDITABLE (we have done this in list but should check again
                    if(this.templateSchemaSettingsCollectionPropertyEditorTypeHasEditableOptions(this.templateSchemaSettingsCollectionProperty.editorType)){

                        this.templateSchemaSettingsCollectionPropertyEditOptionsComponent = this.getTemplateSchemaSettingsCollectionPropertyEditorComponentName(this.templateSchemaSettingsCollectionProperty.editorType);

                        log(["List of components available here", this.$options.components, this.propertyEditOptionsComponent]);

                        if(this.templateSchemaSettingsCollectionPropertyEditOptionsComponent && Object.keys(this.$options.components).includes(this.templateSchemaSettingsCollectionPropertyEditOptionsComponent)){
                            log(["Edit component exists"]);

                            // get options
                            this.templateSchemaSettingsCollectionPropertyConfiguration = this.getTemplateSchemaSettingsCollectionPropertyConfiguration(this.templateSchemaSettingsCollectionProperty, true)
                        }
                        else
                        {
                            allOK = false;
                        }
                    }
                    else
                    {
                        allOK = false;
                    }

                    // Set Page Title
                    this.setPageTitleAction({ title : this.pageTitle.title.replace("%s%", this.templateSchemaSettingsCollectionProperty.name) });
                }
                else
                {
                    allOK = false;
                }

                if(!allOK)
                {
                    // hide page content
                    this.contentStatus = false;

                    // Set Page Title
                    this.setPageTitleAction({ title : this.text.content_404 });
                }
            },
            newPropertyOptionAdded(option, doRefresh = true) {
                log(["New property option added", option, doRefresh]);
                this.addNewTemplateSchemaSettingsCollectionPropertyOption(this.templateSchemaSettingsCollectionProperty, option);

                if(doRefresh){
                    this.refreshEditor();
                }
            },
            updatePropertyOption(option) {
                log(["Update property option", option]);
                this.updateTemplateSchemaSettingsCollectionPropertyOption(this.templateSchemaSettingsCollectionProperty, option);
            },
            deletePropertyOptionByID(id){
              let confirm = window.confirm(lang.message_confirm_content_update_delete);

              if(confirm) {
                log(["Delete property option ", id]);
                if (this.deleteTemplateSchemaSettingsCollectionPropertyOption(this.templateSchemaSettingsCollectionProperty, id)) {
                  this.refreshEditor();
                }
              }
            },
            refreshEditor() {
                log(["Trigger editor refresh", this.$refs, this.$refs.propertyEditor, this.$refs.propertyEditor]);
                this.$refs.propertyEditor.refreshOptions();
            },
            async save() {
                log(["Edit wrapper triggering save"]);
                if(this.$refs.propertyEditor.save()){

                    log(["Edit wrapper saving this data", this.templateSchemaSettingsCollectionProperty]);

                    if(await this.saveTemplateSchemaSettingsCollectionPropertyOptions(this.templateSchemaSettingsCollectionProperty)){
                        this.$router.push( { name: 'AdminTemplateSchemaSettingsCollectionList', params: { message: 'saved'} } );
                    }
                    else
                    {
                        this.$store.dispatch('addSystemMessageAction', {
                            'type' : MESSAGE_TYPE_ERROR,
                            'message' : lang.message_bad_server_response
                        });
                    }
                }
            }
        },
        async mounted() {
            this.initPropertyOptionsEdit();
        }
    }
</script>