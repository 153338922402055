<template>
    <div>
        <div class="table-scroller table-shadow">
            <table class="body">
                <thead>
                <tr>
                    <th class="w-2/12">{{text.property_title}}</th>
                    <th class="w-4/12">{{text.rename}}</th>
                    <th class="w-6/12">{{text.tooltip}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row, index) in propertyFormData" :key="index">
                    <td>{{row[0].id}}</td>
                    <td>
                        <Formelement :elementData="row[0]" v-model="row[0].value"
                                     :ref="'element-' + row[1].alias + '-label'"/>
                    </td>
                    <td>
                        <Formelement :elementData="row[1]" v-model="row[1].value"
                                     :ref="'element-' + row[1].alias + '-tooltip'"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="text-right">
            <a href="#" :title="text.save" class="btn btn-primary btn-smyspace" @click="save($event, false)" @keyup.enter="save($event, false)">{{text.save}}</a>
        </div>
    </div>
</template>

<script>
    import {log} from "@/shared/utils";
    import lang from '@/shared/lang';

    import {templateActionsMixin} from "@/views/mixins/templateActions";
    import {formWrapperMixin} from "@/views/mixins/formwrapper";

    import Formelement from "@/components/form/formelement";
    import {MESSAGE_TYPE_SUCCESS} from "@/shared/consts";

    export default {
        name: 'TemplateSchemaSettingsCollectionsProperties',
        components: {Formelement},
        mixins: [templateActionsMixin, formWrapperMixin],
        data() {
            return {
                propertiesList: [],
                propertyFormData: []
            }
        },
        computed: {
            text() {
                return lang;
            },
            message() {
                return this.$route.params.message
            },
        },
        methods: {
            async initTemplateSectionsEdit(){
                log(["Init template section edit", this.id]);

                await this.loadTemplateSchemaSettingsCollectionByID(1);

                this.initPropertyTable();
            },
            initPropertyTable(){
                log(["Initialising property table", this.getTemplateSchemaProperties()]);
                this.propertyFormData = [];

                this.getTemplateSchemaProperties().forEach((value, index) => {
                    log(["Property", index, value[1]]);
                    this.propertyFormData.push(this.createFormRow(index, value[1]));
                });
            },
            createFormRow(rowID, rowData) {
                return [
                    {
                        id: rowData.name,
                        type: 'text',
                        name: `label-${rowID}`,
                        label: 'Label',
                        placeholder: "",
                        tooltip: "",
                        value: rowData.label,
                        validate: {
                            type: 'string',
                            required: false,
                            max: 63
                        },
                        classString: "hide-label"
                    },
                    {
                        id: rowData.propertyAlias,
                        type: 'text',
                        name: `tooltip-${rowID}`,
                        label: 'Tooltip',
                        placeholder: "",
                        tooltip: "",
                        value: rowData.tooltip,
                        validate: {
                            type: 'string',
                            required: false,
                            max: 511
                        },
                        classString: "hide-label"
                    },
                ];
            },
            validateAll() {
                let pass = true;

                log(["Saving form", this.$refs]);

                this.propertyFormData.forEach(element => {
                    log(["Ref", element, 'element-' + element[1].alias + '-label', this.$refs['element-' + element[1].alias + '-label']]);
                    if (!this.$refs['element-' + element[1].alias + '-label'][0].validateElement()) {
                        pass = false;
                    }
                    if (!this.$refs['element-' + element[1].alias + '-tooltip'][0].validateElement()) {
                        pass = false;
                    }
                    log("Current pass state: " + pass);
                });

                return pass;
            },
            async save(e, navigateNextOnSave = false){
                e.preventDefault();
                log(["Saving new template set up", navigateNextOnSave, this.propertyFormData]);

                if(this.validateAll()){
                    log(["Validation OK"]);

                    // loop through properties and try to save them
                    this.propertyFormData.forEach(element => {
                        log(["looping through save", element, element[1].id, element[0].value, element[1].value]);

                        this.setTemplateSchemaPropertyLabelByAlias(element[1].id, element[0].value);
                        this.setTemplateSchemaPropertyTooltipByAlias(element[1].id, element[1].value);
                    });

                    // save down to the database
                    let databaseSaveOK = await this.saveTemplateSchemaSettingsCollectionByID(1);

                    if(databaseSaveOK){
                        log(["Form has been saved", databaseSaveOK]);

                        this.$store.dispatch('addSystemMessageAction', {
                            'type' : MESSAGE_TYPE_SUCCESS,
                            'message' : this.text.message_template_sections_saved
                        });
                    }
                }
                else
                {
                  log(["FORM NOT OK"]);
                  this.scrollToFirstError();
                }
            },
        },
        async mounted() {
            await this.initTemplateSectionsEdit();
        },
    }
</script>