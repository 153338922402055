<template>
    <div>
        <div class="table-scroller table-shadow">
            <table>
                <thead>
                <tr>
                    <th>{{text.page_name}}</th>
                    <th>{{text.editor_type}}</th>
                    <th>{{text.actions}}</th>
                </tr>
                </thead>
                <tbody v-if="Object.keys(editableTemplateSchemaSettingsCollectionProperties).length > 0">
                    <tr v-for="row in editableTemplateSchemaSettingsCollectionProperties" :key="row.propertyAlias">
                        <td>
                            <router-link :to="{ name: 'AdminTemplateSchemaSettingsCollectionEdit', params: { 'id' : templateSchemaSettingsCollectionID, 'alias' : makeStringURLsafe(row.propertyAlias) } }" :title="text.edit + ' ' + row.name" class="default-action">{{row.name}}</router-link>
                        </td>
                        <td>{{row.editorFriendlyName}}</td>
                        <td>
                            <router-link :to="{ name: 'AdminTemplateSchemaSettingsCollectionEdit', params: { 'id' : templateSchemaSettingsCollectionID, 'alias' : makeStringURLsafe(row.propertyAlias) } }" :title="text.edit + ' ' + row.name" v-if="templateSchemaSettingsCollectionPropertyEditorTypeHasEditableOptions(row.editorType)">{{text.edit}}</router-link>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="3">No rows to display</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import lang from '@/shared/lang';
    //import {log} from '@/shared/utils';

    import {MESSAGE_TYPE_SUCCESS} from "@/shared/consts";

    import {templateSchemaSettingsCollectionActionsMixin} from "@/views/mixins/templateSchemaSettingsCollectionActions";
    import {templateSchemaSettingsCollectionPropertyActionsMixin} from "@/views/mixins/templateSchemaSettingsCollectionPropertyActions";

    export default {
        name: 'TemplateSchemaSettingsCollection',
        mixins: [templateSchemaSettingsCollectionActionsMixin, templateSchemaSettingsCollectionPropertyActionsMixin],
        computed: {
            text() {
                return lang;
            },
            message() {
                return this.$route.params.message
            },
            editableTemplateSchemaSettingsCollectionProperties() {
                return Object.keys(this.templateSchemaSettingsCollectionProperties).length > 0 ? this.getEditableTemplateSchemaSettingsCollectionProperties(this.templateSchemaSettingsCollectionProperties) : {};
            }
        },
        methods: {
            showMessage() {
                if(this.message === "saved"){
                    this.$store.dispatch('addSystemMessageAction', {
                        'type' : MESSAGE_TYPE_SUCCESS,
                        'message' : lang.message_template_schema_settings_collection_edit_saved
                    });
                }
            },
        },
        async mounted() {
            await this.loadTemplateSchemaSettingsCollectionByID(1);
            this.showMessage();
        }
    }
</script>