// define a mixin object
import {makeStringURLsafe,log} from "@/shared/utils";

import {getTemplateSchemaSettingsCollectionByID} from "@/shared/dataservice";

export const templateSchemaSettingsCollectionActionsMixin = {
    data() {
        return {
            templateSchemaSettingsCollection: {}
        }
    },
    computed: {
        templateSchemaSettingsCollectionProperties() {
            return this.getTemplateSchemaSettingsCollectionProperties();
        },
        templateSchemaSettingsCollectionID() {
            return this.getTemplateSchemaSettingsCollectionID();
        }
    },
    methods: {
        /**
         * TODO: MOVE GET FUNCTIONS FROM TemplateSchemaSettingsCollectionEDIT??
         */

        async loadTemplateSchemaSettingsCollectionByID(id = -1) {
            let result = await getTemplateSchemaSettingsCollectionByID(id); /* WHILE THERE IS ONLY ONE COLLECTION PASSING -1 GETS FIRST (OR ONLY) */

            log(["Loaded templatessc", result]);

            this.templateSchemaSettingsCollection = result.data.data;
        },

        getTemplateSchemaSettingsCollectionID() {
            return this.templateSchemaSettingsCollection.id;
        },

        /**
         * MANIPULATE COLLECTION
         */
        getTemplateSchemaSettingsCollectionProperties() {
            return this.templateSchemaSettingsCollection.hasOwnProperty("propertySettings") ? this.templateSchemaSettingsCollection.propertySettings : {};
        },

        /**
         * UTILS
         */
        makeStringURLsafe(string) {
            return makeStringURLsafe(string);
        },


    },
}